export default class Utils {
    static checkCardNumber(digits) {
        if(digits.length < 16) return false;
        let sum = 0;
        for (let i = 0; i < digits.length; i++) {
            let cardNum = parseInt(digits[i]);

            if ((digits.length - i) % 2 === 0) {
                cardNum = cardNum * 2;

                if (cardNum > 9) {
                    cardNum = cardNum - 9;
                }
            }

            sum += cardNum;
        }
        return sum % 10 === 0;
    }

    static setDataInAcsFormAndSubmit(data) {
        // console.log('ACS data:', data);
        document.querySelectorAll('#acs_form input').forEach(input => input.removeAttribute('disabled'))
        document.querySelector('#acs_form').setAttribute('action', data.acs_url);
        const form = document.querySelector('#acs_form');
        if(data.pa_req) {
            document.querySelector('#acs_form [name=PaReq]').value = data.pa_req
        } else {
            this.setDisabled('PaReq')
        }
        if(data.md) {
            document.querySelector('#acs_form [name=MD]').value = data.md
        } else {
            this.setDisabled('MD')
        }
        if(data.term_url) {
            document.querySelector('#acs_form [name=TermUrl]').value = data.term_url
        } else {
            this.setDisabled('TermUrl')
        }

        if(data.body && typeof data.body === "object") {
            Object.keys(data.body ).map((objectKey)=> {
                form.insertAdjacentHTML("afterbegin", `<input type="hidden" name=${objectKey} value=${data.body[objectKey]}>`);
            });
        }
        // console.log('ACS data:', form);
        document.querySelector('#acs_form').submit();
    }

    static setDisabled(input) {
        document.querySelector(`#acs_form [name=${input}]`).setAttribute('disabled', 'disabled')
    }

    static getNameByDomain() {
        let name = '';
        const domain = window.location.hostname;

        if(/tixwall/.test(domain)) {
            name = 'mntx'
        }

        if(/solaris/.test(domain)) {
            name = 'slg'
        }

        if(/force-s/.test(domain)) {
            name = 'force'
        }

        if(/raa-group/.test(domain)) {
            name = 'agraa'
        }

        return name;
    }

    static needValidate() {
        return this.getNameByDomain() === 'force' ? false : true;
    }

    static extractDomain() {
        let domain;
        let url = window.location.hostname;

        //find & remove protocol (http, ftp, etc.) and get domain
        if (url.indexOf("://") > -1) {
            domain = url.split('/')[2];
        }
        else {
            domain = url.split('/')[0];
        }

        //find & remove www
        if (domain.indexOf("www.") > -1) {
            domain = domain.split('www.')[1];
        }


        domain = domain.split(':')[0]; //find & remove port number
        domain = domain.split('?')[0]; //find & remove url params

        let domainArr = domain.split('.')
        domain = [domainArr[domainArr.length - 2] , domainArr[domainArr.length - 1]].join('.')

        return domain;
    }
}
