import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index.vue';
import Result from '../views/Result';
import Error from '../views/Error';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/result',
        name: 'app-result',
        component: Result,
    },
    {
        path: '/error',
        name: 'app-error',
        component: Error,
    },

];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
