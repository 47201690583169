<template>
    <div id="app" :class="brand">
        <router-view />
        <div
            v-if="getStateLoader"
            class="loader">
            <div class="loader_img" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Utils from '@/helpers/Utils';

export default {
    name: 'App',
    computed:{
        ...mapGetters([
            'getStateLoader'
        ])
    },
    data: () => ({
        brand:Utils.getNameByDomain(),
    })
}
</script>

<style lang="scss">

$form-label-font-size: .8em;
$input-font-size: .75em;

@import "bootstrap/scss/bootstrap-utilities.scss";
@import "bootstrap/scss/reboot.scss";
@import "bootstrap/scss/containers.scss";
@import "bootstrap/scss/grid.scss";
@import "bootstrap/scss/card.scss";
@import "bootstrap/scss/buttons.scss";
@import "bootstrap/scss/forms.scss";

@media screen and (min-width: 991px) {
    html,body,#app {
        min-height: 100%;
        background: #f4f4f4;
    }
}

#app {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background: #f4f4f4;
}

.loader {
    position: fixed;
    z-index: 100;
    background: #f4f4f4;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & .loader_img {
        width: 65px;
        height: 65px;
        background-image: url("assets/loader.png");
        background-size: contain;
        background-repeat: no-repeat;
        animation-name: rotate;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

@keyframes rotate { from { transform: rotate(0deg); } to { transform: rotate(360deg); } }

</style>
