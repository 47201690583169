<template>
    <div class="container">
        <div class="row justify-content-md-center py-4">
            <div class="col col-lg-8 px-lg-12">
                <h1>Перевод осуществлен</h1>
                <p>По всем вопросам вы можете обратиться в службу поддержки.</p>
                <p>
                    <a
                        :href="'mailto:info@' + domain"
                    >{{ 'info@' + domain }}</a>
                </p>
                <p><button v-on:click="goHome">Продолжить</button></p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "app-result",
    data: () => ({
        domain:''
    }),
    methods: {
        goHome() {
            this.$router.push('/');
        }
    },
    mounted() {
        this.domain = window.location.hostname.split('.').slice(-2).join('.');
    }
}
</script>

<style scoped>
    h1 {
        font-size: 25px;
        color: #3c9544;
        padding: 30px;
        text-align: center;
    }
    p {
        text-align: center;
        padding: 20px;
        color: #706e6b;
    }
    button {
        font-size: 1rem;
        padding: .5em 2em;
    }
</style>
